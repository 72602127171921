
.container {
    margin-left: 4em;
    margin-right: 4em;
}

.menudedroite {
    display: flex;
    flex-direction: row;
}

.menudedroite1 {
    margin-left: 1em;
    margin-top: 2em;
    margin-bottom: 1em;
    margin-right: 1em;
}

.menudedroite2 {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
    margin-bottom: 1em;
}

.menudegauche {
    margin-top: 2em;
    margin-bottom: 1em
}

.menuglobal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.laPage {
    padding: 2em;
}

.containerRecherche {
    padding-bottom: 6em;
    padding-left: 4em;
    padding-right: 4em;
}

.formRecherche {
    display: flex;
    flex-direction: row;
    margin-top: 1.5em;
    justify-content: space-between;
}

.livre {
    font-weight:bold;
    font-size: 1.4em;
}

@media all and (max-width: 950px)
{

    .laPage {
        padding-top: 1em;
        padding-left: 1em;
        padding-right: 1em;
    }

    .container {
        width: 100%;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    .containerRecherche {
        width: 100%;
        padding-bottom: 6em;
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    .menudedroite {
        display: flex;
        flex-direction: column;
    }

    .menudedroite1 {
        margin-left: 1em;
        margin-top: 1.5em;
        margin-bottom: 1em;
        margin-right: 0em;
    }

    .menudedroite2 {
        display: flex;
        flex-direction: row;
        margin-top: 0;
        margin-bottom: 0;
    }

    .menudegauche {
        margin-top: 1.5em;

    }

}

@media all and (max-width: 600px)
{

    .laPage {
        padding-top: 1em;
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    .containerRecherche {
        width: 100%;
        padding-bottom: 6em;
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    .formRecherche {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 1.5em;
        justify-content: space-between;
    }

    .menuglobal {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .menudedroite {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0.5em;
    }

    .menudedroite1 {
        margin-left: 1em;
        margin-top: 0.2em;
        margin-bottom: 1em;
    }

    .menudedroite22 {
        margin-left: 0em;
    }

    .livre {
        display: inline-block;
        max-width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight:bold;
        font-size: 1.4em;
    }

}
