body{
    margin: 0;
    padding:0;
    background: url("./back.webp") center center no-repeat fixed; 
    background-size: cover; 
}

footer{
  background-color: #1b1c1d;
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   height: 22px;
   text-align: center;
   color: #FFFFFF;
}

.fade-enter{
  opacity: 0;
}
.fade-enter-active{
  opacity: 1;
  transition: opacity 1500ms;
}
.fade-exit{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
  transition: opacity 300ms;
}

@media only screen and (max-width: 767px) {
    body {
      background-image: url("./mobile_back.webp");
      height: 100%;
    }
  }